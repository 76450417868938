<template>
  <v-container fluid>
    <basic-card
      title="$l.api.subjects.apiSubjectCard"
      class="mt-9"
    >
      <SubjectCard
        :card-id="$route.params.cardId"
        full-page
      />
    </basic-card>
  </v-container>
</template>

<script>
import SubjectCard from '@/components/api/apiSubject/SubjectCard.vue'

export default {
  name: 'ApiSubjectCardLayout',
  components: { SubjectCard }
}
</script>
