<template>
  <ApiSubjectCardLayout />
</template>

<script>
import ApiSubjectCardLayout from '@/components/api/apiSubject/ApiSubjectCardLayout'

export default {
  name: 'ApiSubjectCard',
  components: {
    ApiSubjectCardLayout
  }
}
</script>
